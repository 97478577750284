import { useSelector } from "react-redux";
import { Box } from "../../components/StyledComponent";
// import { RoutePattern } from "../../routes/RoutePattern";
import { RootState } from "../../Store";
// import EmergingTalent from "./EmergingTalent/EmergingTalent";
// import Guests from "./Guests/Guests";
import RSVP from "./RSVP/RSVP";
import Showcase from "./Showcase/Showcase";
import { motion, Variants } from "framer-motion";
import SpecialGuest from "./SpecialGuest/SpecialGuest";

const variant = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

const variant2: Variants = {
  visible: {
    opacity: 1,
    position: "relative",
    bottom: "0%",
  },
  hidden: {
    opacity: 0,
    position: "fixed",
    bottom: "-100%",
  },
};

export default function Home() {
  const { isScrollableVideoEnded } = useSelector(
    (state: RootState) => state.Global
  );
  return (
    <Box className="text-white w-full flex flex-col justify-center items-center">
      <Showcase />
      {/* <RSVP /> */}
      <SpecialGuest />
    </Box>
  );
}
