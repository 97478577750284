import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { isMobile } from "../../constants";
import { Box } from "../StyledComponent";
import { RocnationSliderTypes } from "./types";

interface RocnationSliderProps {
  data: RocnationSliderTypes[];
}

const RocnationSlider = ({ data }: RocnationSliderProps) => {
  const [selectedVideoId, setSelectedVideoId] = useState<number | null>(0);
  const rocnationVideos = document.querySelectorAll(".rocnation-video");

  useEffect(() => {
    rocnationVideos.forEach((item: any) => {
      if (parseInt(item.id) === selectedVideoId) {
        item.currentTime = 0;
        item.play();
      } else if (selectedVideoId === null) {
        item.pause();
      } else {
        item.pause();
      }
    });
  }, [selectedVideoId, rocnationVideos]);

  function handleTimeUpdate(e: React.SyntheticEvent<HTMLVideoElement, Event>) {
    //
  }

  function handleEnded() {
    if (selectedVideoId) {
      if (selectedVideoId! < rocnationVideos.length) {
        setSelectedVideoId((prev) => prev && prev + 1);
      } else if (selectedVideoId !== 0) {
        setSelectedVideoId((prev) => prev && prev - 1);
      }
    }
  }

  const items = data.map(({ src, id, mobile }, idx) => {
    return (
      <Box key={idx}>
        <video
          preload="metadata"
          playsInline
          src={isMobile ? mobile : src}
          id={id as any}
          muted
          autoPlay
          loop
          onTimeUpdate={(e) => handleTimeUpdate(e)}
          onEnded={handleEnded}
          className="rocnation-video h-screen w-full object-cover"
        />
        <div className="bg-black absolute top-0 left-0 w-full h-full opacity-20"></div>
        {/* {selectedVideoId === id ? (
          <button
            className="absolute bottom-24 left-1/2 transform -translate-x-1/2 border border-gray-500 block w-10 h-10 text-white hover:text-black rounded-full hover:border-white hover:shadow-md"
            onClick={() => setSelectedVideoId(null)}
          >
            <span className="bg-gray-500 bg-opacity-50 mx-auto rounded-full w-5/6 h-5/6 flex hover:bg-white">
              <FontAwesomeIcon icon={faPause as any} className="m-auto" />
            </span>
          </button>
        ) : (
          <button
            className="absolute bottom-24 left-1/2 transform -translate-x-1/2 border border-gray-500 block w-10 h-10 text-white hover:text-black rounded-full hover:border-white hover:shadow-md"
            onClick={() => setSelectedVideoId(id)}
          >
            <span className="bg-gray-500 bg-opacity-50 mx-auto rounded-full w-5/6 h-5/6 flex hover:bg-white">
              <FontAwesomeIcon icon={faPlay as any} className="m-auto" />
            </span>
          </button>
        )} */}
      </Box>
    );
  });

  return (
    <Carousel
      className="rocnation-slider"
      infiniteLoop
      swipeable={false}
      emulateTouch={false}
      showThumbs={false}
      showStatus={false}
      showArrows={false}
      showIndicators={false}
      autoPlay
      interval={5000}
    >
      {items}
    </Carousel>
  );
};

export default RocnationSlider;
