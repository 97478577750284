import {
  faPause,
  faPlay,
  faVolumeHigh,
  faVolumeMute,
  faVolumeOff,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { scroller } from "react-scroll";
import styled from "styled-components";
import AMBRE_IMG from "../../../assets/images/Ambre.png";
import DIXSON_IMG from "../../../assets/images/Dixson.png";
import MAETA_IMG from "../../../assets/images/Maeta.png";
import EMERGINGTALENT_TEXT from "../../../assets/images/EMERGING TALENTS.png";
import VIDEO_DESKTOP from "../../../assets/videos/Emerging Talent/Emerging_Talent_Desktop.mp4";
import POSTER_DESKTOP from "../../../assets/videos/Emerging Talent/Emerging_Talent_Desktop_End.png";
import VIDEO_MOBILE from "../../../assets/videos/Emerging Talent/Emerging_Talent_Mobile.mp4";
import POSTER_MOBILE from "../../../assets/videos/Emerging Talent/Emerging_Talent_Mobile_End.png";
import { Box, Heading, Image } from "../../../components/StyledComponent";
import { isMobile } from "../../../constants";
import { RoutePattern } from "../../../routes/RoutePattern";
import useGlobalService from "../../Hooks/useGlobalService";
import Guests from "../Guests/Guests";
import RSVP from "../RSVP/RSVP";
import SPECIALGUESTS_TEXT from "../../../assets/images/SPECIAL GUESTS.png";

const InfoTitle = styled.span`
  font-size: 2rem;
  font-weight: bold;
  font-family: Antro Vectra;
`;

const VolumeBtn = styled.button`
  bottom: 7%;
`;

const InfoDescription = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  opacity: 0;
  transition: all 0.3s ease;
  text-shadow: 2px 2px 4px black;
  padding: 0 2rem;
  font-family: Avenir LT Pro;

  :hover {
    opacity: 1;
  }

  font-size: 70%;

  @media (min-width: 480px) {
    font-size: 100%;
  }
`;

const Info = styled.div`
  position: relative;
  background-color: black;
  z-index: 123123123;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 1024px) {
    width: 25%;
  }
`;

const SpecialGuest = () => {
  const [active, setActive] = useState<boolean>(false);
  const [videoEnded, setVideoEnded] = useState<boolean>(false);
  const [selectedArtistId, setSelectedArtistId] = useState<number | null>(null);
  const [hit, setHit] = useState<boolean>(false);
  const [muted, setMuted] = useState<boolean>(true);
  const videoRef: any = useRef(null);
  const imageRef: any = useRef(null);
  const { setIsScrollableVideoEnded } = useGlobalService();

  // useEffect(() => {
  //   videoRef!.current.currentTime = 20;
  // }, []);

  // handle scroll
  useEffect(() => {
    document.addEventListener("scroll", () => {
      start();
    });
  }, [videoEnded]);

  // handle hit
  useEffect(() => {
    const video = videoRef.current as HTMLVideoElement;

    if (hit) {
      video.play();
    }
  }, [hit]);

  // this handles if video is ended
  useEffect(() => {
    const video = videoRef.current as HTMLVideoElement;

    if (videoEnded) {
      video.remove();
      displayCarousel(true);

      // scroller
      scroller.scrollTo(RoutePattern.SPECIAL_GUESTS, {
        duration: 500,
        delay: 100,
        smooth: true,
      });

      setIsScrollableVideoEnded(true);
    }
  }, [videoEnded]);

  function handleClick(value: boolean) {
    const video = videoRef.current as HTMLVideoElement;
    const img = imageRef.current as HTMLImageElement;

    setActive(value);

    if (value) {
      video.play();
      video.muted = false;
    } else {
      // simply pauses the video
      video.pause();
    }
  }

  const start = () => {
    const video = videoRef.current as HTMLVideoElement;

    const top = video.getBoundingClientRect().top;
    const scrollY = window.scrollY;

    if (top <= scrollY && !videoEnded) {
      setHit(true);
    }
  };

  function displayCarousel(value: boolean) {
    if (!value) {
      const infoContainer = document.getElementById("info-container") as any;

      infoContainer.classList.remove("opacity-100");
      infoContainer.classList.add("opacity-0");
    } else {
      const infoContainer = document.getElementById("info-container") as any;

      infoContainer.classList.add("opacity-100");
      infoContainer.classList.remove("opacity-0");
    }
  }

  return (
    <Box
      id={RoutePattern.SPECIAL_GUESTS}
      className="relative flex flex-col justify-center items-center w-full overflow-hidden py-10"
    >
      <Box className="z-30 absolute top-0 left-0 h-full w-full">
        <video
          ref={videoRef}
          preload="metadata"
          playsInline
          autoPlay
          muted
          poster={isMobile ? POSTER_MOBILE : POSTER_DESKTOP}
          src={isMobile ? VIDEO_MOBILE : VIDEO_DESKTOP}
          onEnded={() => setVideoEnded(true)}
          className="h-full w-full object-cover block object-bottom"
        />
      </Box>

      {/* content */}
      <div className="z-50 w-full px-10 md:px-24 ">
        <Image
          src={SPECIALGUESTS_TEXT}
          className="w-56 mx-auto lg:mx-0 md:w-96 z-50"
        />
      </div>
      <Box
        id="info-container"
        className="px-10 md:px-24 flex w-full h-full z-50 opacity-0 transition-all"
      >
        <Guests />
      </Box>

      {/* controls */}
      <Box>
        {videoEnded ? null : muted ? (
          <VolumeBtn
            className="absolute left-2 md:left-5 border border-gray-500 block w-7 h-7 text-sm md:w-16 md:h-16 md:text-lg text-white hover:text-black rounded-full hover:border-white hover:shadow-md z-50"
            onClick={() => {
              setMuted(false);
              videoRef.current.muted = false;
            }}
          >
            <span className="bg-gray-500 bg-opacity-50 mx-auto rounded-full w-5/6 h-5/6 flex hover:bg-white">
              <FontAwesomeIcon icon={faVolumeMute as any} className="m-auto" />
            </span>
          </VolumeBtn>
        ) : (
          <VolumeBtn
            className="absolute left-2 md:left-5 border border-gray-500 block w-7 h-7 text-sm md:w-16 md:h-16 md:text-lg text-white hover:text-black rounded-full hover:border-white hover:shadow-md z-50"
            onClick={() => {
              setMuted(true);
              videoRef.current.muted = true;
            }}
          >
            <span className="bg-gray-500 bg-opacity-50 mx-auto rounded-full w-5/6 h-5/6 flex hover:bg-white">
              <FontAwesomeIcon icon={faVolumeHigh as any} className="m-auto" />
            </span>
          </VolumeBtn>
        )}

        {videoEnded ? null : (
          <VolumeBtn
            className="absolute right-2 md:right-5 block w-7 h-7 text-sm md:w-16 md:h-16 md:text-lg text-white hover:text-primary rounded-full hover:border-white hover:shadow-md z-50"
            onClick={() => {
              setVideoEnded(true);
            }}
          >
            Skip
          </VolumeBtn>
        )}
      </Box>
    </Box>
  );
};

export default SpecialGuest;
