import {
  Box,
  Button,
  Heading,
  Image,
} from "../../../components/StyledComponent";
import ROCNATION_TEXT from "../../../assets/images/ROCNATION LABEL PRESENTS.png";
import { RoutePattern } from "../../../routes/RoutePattern";
import RocnationSlider from "../../../components/RonationSlider/RocnationSlider";
import VIDEO1 from "../../../assets/videos/Hero/Hero V4.mp4";
import VIDEO1_MOBILE from "../../../assets/videos/Hero/Mobile Hero V4.mp4";
import VIDEO2 from "../../../assets/videos/Hero/Hero_Ambre.mp4";
import VIDEO2_MOBILE from "../../../assets/videos/Hero/Hero Mobile_Ambre.mp4";
import VIDEO3 from "../../../assets/videos/Hero/Hero_Dixson.mp4";
import VIDEO3_MOBILE from "../../../assets/videos/Hero/Hero Mobile_Dixson.mp4";
import VIDEO4 from "../../../assets/videos/Hero/Hero_Maeta.mp4";
import VIDEO4_MOBILE from "../../../assets/videos/Hero/Hero Mobile_Maeta.mp4";
import { Link as ScrollLink } from "react-scroll";
import HEADING_TEXT from "../../../assets/images/A Night of R&B.png";
import DATE_TEXT from "../../../assets/images/date-location 9-7 centered.png";

const Showcase = () => {
  return (
    <Box id={RoutePattern.HOME} className="h-screen relative">
      {/* <Image
        src={ROCNATION_TEXT}
        className="w-56 md:w-1/4 absolute top-10 left-10 z-20"
      /> */}
      <Heading
        level={2}
        className="w-3/4 absolute top-10 left-1/2 transform -translate-x-1/2 lg:-translate-x-0 lg:left-10"
      >
        {/* ROCNATION LABEL PRESENTS <span className="text-xl">&copy;</span> */}
        <img
          src={ROCNATION_TEXT}
          className="w-full lg:w-1/2"
          alt="rocnation label presents"
        />
      </Heading>
      <RocnationSlider
        data={[
          {
            id: 0,
            src: VIDEO1,
            mobile: VIDEO1_MOBILE,
          },
        ]}
      />
      <Box className="px-5 text-white absolute left-1/2 -translate-x-1/2 md:left-3/4 top-1/2 transform -translate-y-1/2 md:translate-x-1">
        <Heading>
          {/* A Night <br />
          of R&amp;B */}
          <img src={HEADING_TEXT} alt="A Night of R&B" />
        </Heading>
        <img className="mt-5" src={DATE_TEXT} alt="" />
        {/* <span className="font-vladimir-script text-2xl md:text-4xl mt-5 block text-center">
          06.24.22
        </span> */}
        {/* <ScrollLink
          to={RoutePattern.RSVP}
          smooth
          duration={1000}
          className="text-center flex"
        >
          <Button className="mt-2 mx-auto">RSVP</Button>
        </ScrollLink> */}
      </Box>
    </Box>
  );
};

export default Showcase;
