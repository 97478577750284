import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Footer, Navigation, Preloader } from "./components";
import Libs from "./Libs";
import AppRoutes from "./routes/AppRoutes";

export default function App() {
  useEffect(() => {
    Libs(); // all libs
  }, []);

  return (
    <>
      <Router>
        <Preloader />
        {/* <Navigation /> */}
        <Routes>
          <Route path="*" element={<AppRoutes />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}
