import { Box, Heading, Image } from "../../../components/StyledComponent";
import { RoutePattern } from "../../../routes/RoutePattern";
import GUEST3 from "../../../assets/images/Dixson.png";
import GUEST2 from "../../../assets/images/Maeta.png";
import GUEST1 from "../../../assets/images/Ambre.png";
import { isMobile } from "../../../constants";
import FEATURING_TEXT from "../../../assets/images/Featuring.png";
import GUESTS_TEXT from "../../../assets/images/Ambre.Maeta.Dixson.png";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const FeaturingText = styled.img`
  width: 5rem;
  @media (min-width: 768px) {
    width: 10rem;
  }
`;

const GuestsText = styled.img`
  width: 15rem;
  @media (min-width: 768px) {
    width: 30rem;
  }
`;

const Guests = () => {
  return (
    <Box className="relative flex flex-col w-full m-auto">
      {/* <Image src={GUESTSARTISTS_TEXT} className="w-48 md:w-80 z-20" /> */}
      {/* <div className="text-left">
        <Heading level={2}>
          <span className="lg:text-7xl">SPECIAL GUESTS</span>
        </Heading>
      </div> */}
      <div className="flex flex-col items-center justify-center mt-5 lg:mt-7 mx-auto">
        <FeaturingText className="mr-2" src={FEATURING_TEXT} alt="featuring" />
        <GuestsText src={GUESTS_TEXT} alt="AMBRE + MAETA + DIXSON" />
      </div>
      <Box className="mt-12 w-full">
        <Slider
          arrows={false}
          dots={false}
          slidesToShow={3}
          responsive={[
            {
              breakpoint: 768,
              settings: {
                dots: true,
                slidesPerRow: 1,
                slidesToScroll: 1,
                slidesToShow: 1,
              },
            },
          ]}
        >
          <Image src={GUEST1} className="h-auto  md:w-full" />
          <Image src={GUEST2} className="h-auto md:w-full" />
          <Image src={GUEST3} className="h-auto md:w-full" />
        </Slider>
      </Box>
      {/* <Box className="flex-row flex-wrap justify-center items-center mt-12 gap-10 hidden lg:flex">
        <Image src={GUEST1} className="h-auto md:w-96" />
        <Image src={GUEST2} className="h-auto md:w-96" />
        <Image src={GUEST3} className="h-auto md:w-96" />
      </Box> */}
    </Box>
  );
};

export default Guests;
