import { Link as ScrollLink } from "react-scroll";
import { data } from "../Navigation/data";
import SocialIcons from "../SocialIcons/SocialIcons";
import { Anchor, Box, Container, Text } from "../StyledComponent";

export default function Footer() {
  const menuItems = data.map((item, idx) =>
    // this statement is application only if we're at home
    item.isRoute ? (
      <ScrollLink
        key={idx}
        to={item.to} // id of the section
        smooth
        duration={1000}
        spy={true}
        className="mr-2 text-gray-500 capitalize text-center md:mr-5 text-shadow cursor-pointer text-shadow text-lg"
      >
        {idx === 0 || idx === data.length ? null : (
          <span className="mr-3">|</span>
        )}
        {item.name}
      </ScrollLink>
    ) : (
      <a
        key={idx}
        href={item.to} // route
        className="mr-2 md:mr-5 text-shadow text-shadow text-sm"
      >
        {item.name}
      </a>
    )
  );

  return (
    <Container
      id="footer"
      className="w-full px-5 md:px-24 py-10 mx-auto text-center bg-black font-lucida-grande"
    >
      <Box className="text-white">{menuItems}</Box>
      <Text className="text-xxs md:text-xs text-primary text-center font-bold mt-5 block">
        &copy; {new Date().getFullYear()} ROCNATION LABEL PRESENTS
      </Text>
      {/* <SocialIcons /> */}
    </Container>
  );
}
